<template>
  <div v-if="pageCount > 1" class="pagination">
    <NuxtLink
      :to="previousPageLink"
      class="navigation-button"
      @click="$emit('change')"
    >
      <ArrowLeftIcon class="arrow-icon" aria-hidden="true" role="img" />
    </NuxtLink>
    <div class="pagination-pages t-h5">
      <Component
        :is="getTag(page)"
        v-for="page in getPaginationRange(pagination.page.value, pageCount)"
        :key="String(page)"
        class="pagination-page"
        :class="{ 'pagination-page--active': page === pagination.page.value }"
        :to="page ? pagination.getPageLink(page) : undefined"
        @click="$emit('change')"
      >
        {{ page ? page : '...' }}
      </Component>
    </div>
    <NuxtLink
      :to="nextPageLink"
      class="navigation-button"
      @click="$emit('change')"
    >
      <ArrowRightIcon class="arrow-icon" aria-hidden="true" role="img" />
    </NuxtLink>
  </div>
  <div v-else />
</template>

<script lang="ts" setup>
import { range, sortBy } from 'lodash-es'

import ArrowLeftIcon from '@/assets/icons/arrow-left.svg?component'
import ArrowRightIcon from '@/assets/icons/arrow-right.svg?component'

const props = defineProps<{
  pageCount: number
}>()

const pagination = usePagination()
const nextPageLink = computed(() => {
  if (pagination.page.value < props.pageCount)
    return pagination.nextPageLink.value
})

const previousPageLink = computed(() => {
  if (pagination.page.value > 1) return pagination.previousPageLink.value
})

const getTag = (page: number | null) =>
  page ? resolveComponent('NuxtLink') : 'span'
const getPaginationRange = (current: number, final: number) => {
  if (final <= 5) {
    return range(1, final + 1)
  }
  // We always include the first two pages, last two pages, and
  // two pages either side of the current page.
  const included = new Set([1, current - 1, current, current + 1, final])
  // If the break would only exclude a single page number then we
  // may as well include the page number instead of the break.
  if (current <= 4) {
    included.add(2)
    included.add(3)
  }
  if (current >= final - 3) {
    included.add(final - 1)
    included.add(final - 2)
  }
  // Now sort the page numbers and drop anything outside the limits.
  const includedArray: (number | null)[] = sortBy(Array.from(included)).filter(
    idx => idx > 0 && idx <= final
  )
  // Finally insert any `...` breaks
  if (current > 4) {
    includedArray.splice(1, 0, null)
  }
  if (current < final - 3) {
    includedArray.splice(includedArray.length - 1, 0, null)
  }
  return includedArray
}
</script>

<style lang="scss" scoped>
.pagination {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  display: flex;
  gap: rem(24px);
  align-items: center;
  justify-content: center;
  margin-top: rem(80px);
}

.navigation-button {
  display: flex;
  width: rem(14px);
}

.navigation-button:not([href]) {
  opacity: 0.25;
}

.arrow-icon {
  height: fit-content;
}

.pagination-pages {
  display: flex;
  gap: rem(12px);
}

.pagination-page {
  position: relative;
  text-align: center;
  border-top: rem(1.8px) solid transparent;

  &--active {
    &::after {
      position: absolute;
      top: rem(-10px);
      left: 50%;
      display: block;
      width: rem(16px);
      height: rem(1.8px);
      content: '';
      background-color: $black;
      transform: translateX(-50%);
    }
  }

  &--disabled {
    opacity: 0.25;
  }
}
</style>
