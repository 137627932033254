<template>
  <div v-if="isReady" class="grid">
    <div v-if="highlighted.data.value" class="highlighted">
      <WidgetHeading
        class="heading"
        :content="highlighted.data.value.highlighted_section_name"
      />
      <div class="overflow-wrap">
        <NewsItem :news="highlighted.data.value" />
      </div>
    </div>
    <div
      class="news-list"
      :class="{ 'news-list--full-width': !highlighted.data.value }"
    >
      <WidgetHeading class="heading news-list-heading" :content="title">
        <template #right>
          <div class="navigation">
            <button
              :aria-label="t('slider.previous')"
              class="navigation-button navigation-button--prev"
            >
              <ChevronIconLeft class="icon" aria-hidden="true" role="img" />
            </button>
            <button
              :aria-label="t('slider.next')"
              class="navigation-button navigation-button--next"
            >
              <ChevronIconRight class="icon" aria-hidden="true" role="img" />
            </button>
          </div>
        </template>
      </WidgetHeading>
      <div v-if="newsList.data.value?.results" class="overflow-wrap">
        <Swiper
          :breakpoints="{
            0: {
              slidesPerView: 1.48,
              spaceBetween: 10,
              slidesOffsetAfter: 20,
              slidesOffsetBefore: 20
            },
            768: {
              slidesPerView: highlighted.data.value ? 1.06409 : 2.18,
              spaceBetween: 16,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: highlighted.data.value ? 0 : 32
            },
            1280: {
              slidesPerView: highlighted.data.value ? 3 : 4,
              spaceBetween: 20,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0
            }
          }"
          :navigation="
            newsList.data.value?.results.length > 1 && {
              prevEl: `#widget-${widgetId} .navigation-button--prev`,
              nextEl: `#widget-${widgetId} .navigation-button--next`
            }
          "
        >
          <SwiperSlide
            v-for="(newsItem, index) in newsList.data.value?.results"
            :key="newsItem.id"
            class="slide"
            :swiper-slide-index="index"
          >
            <NewsItem :news="newsItem" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div v-if="moreLink" class="more-button">
      <BaseButton :base-link="moreLink" color="primary">
        <span class="wildwaffle" v-html="t('see-all')" />
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChevronIconLeft from '@/assets/icons/chevron-left.svg?component'
import ChevronIconRight from '@/assets/icons/chevron-right.svg?component'
import { PublicLink } from '@/service/__generated-api'

defineProps<{
  title: string
  moreLink: PublicLink | null
  widgetId: string
}>()

const { t } = useI18n()

const getFirstResult = <T,>({ results }: { results?: T[] }) => {
  if (!results) return null
  const [result] = results
  return result || null
}

const newsList = useAsyncData(() =>
  useApi().publicPagesNewsList({ is_highlighted: false, page_size: 12 })
)

const highlighted = useAsyncData(() =>
  useApi()
    .publicPagesNewsList({
      is_highlighted: true,
      page_size: 1
    })
    .then(getFirstResult)
)

const isReady = computed(
  () => !newsList.pending.value && !highlighted.pending.value
)
</script>
<style lang="scss" scoped>
@include media-up(md) {
  .grid {
    grid-template-rows: min-content 1fr min-content;
  }

  .highlighted,
  .news-list {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: 1/3;
  }
}

.overflow-wrap {
  position: relative;
  overflow: hidden;
}

.highlighted {
  @include media-column(
    (
      xs: 4,
      lg: 3
    )
  );
  margin-bottom: rem(64px);
  @include media-up(md) {
    margin-bottom: 0;
  }
}

.news-list-heading {
  margin-right: space-around(xs);
  margin-left: space-around(xs);
  @include media-up(md) {
    margin-right: space-around(md);
    margin-left: 0;

    .news-list--full-width & {
      margin-left: space-around(md);
    }
  }
  @include media-up(lg) {
    margin-right: 0;
    margin-left: 0;

    .news-list--full-width & {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.news-list {
  @include column(xs, all);
  min-width: 0;
  @include media-up(md) {
    grid-column: 10/18;

    &--full-width {
      @include column(md, all);
    }
  }
  @include media-up(lg) {
    @include column(lg, 9, 3);

    &--full-width {
      @include column(lg, 12);
    }
  }
}

.heading {
  margin-bottom: rem(16px);

  @include media-up(lg) {
    margin-bottom: rem(24px);
  }
}

.more-button {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  display: flex;
  justify-content: center;
  margin-top: rem(40px);
  @include media-up(md) {
    margin-top: rem(56px);
  }
  @include media-up(lg) {
    margin-top: rem(64px);
  }
}

.navigation {
  display: flex;
  gap: rem(8px);
  align-items: center;
  justify-content: flex-end;
}

.navigation-button {
  @include focus-visible;
  display: flex;
}

.icon {
  @include media-up(lg) {
    width: rem(5px);
  }
}
</style>
